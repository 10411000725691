@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    height: 100vh;
}

tr {
    @apply bg-white dark:border-gray-700 dark:bg-gray-800
}

.add-btn {
    @apply rounded-lg px-5 py-3 bg-transparent border border-darkBlue text-darkBlue transition hover:text-medBlue focus:outline-none focus:ring
}

.delete-btn {
    @apply block rounded-lg bg-red-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-red-700 focus:outline-none focus:ring
}

.edit-btn {
    @apply block gap-2 rounded-lg px-5 py-3 text-darkBlue hover:text-medBlue focus:outline-none focus:ring
}

.save-btn {
    @apply block rounded-lg bg-gold px-5 py-3 text-sm font-medium text-white transition hover:bg-darkGold focus:outline-none focus:ring
}

.nav-button {
    @apply flex items-center justify-center gap-2 rounded-lg bg-transparent border border-gold px-4 py-2 text-white hover:text-gold min-w-max md:w-48 lg:w-64
}

.nav-button.active {
    @apply bg-gold hover:bg-darkGold px-4 py-2 text-white
}

.nav-link {
    @apply flex items-center justify-center gap-2 rounded-lg px-4 py-2 text-white hover:text-gold
}

.nav-link.active {
    @apply text-gold hover:text-darkGold
}

.full-screen-center {
    @apply w-full h-full flex items-center justify-center
}

.tab-button {
    @apply -mb-px p-4 text-darkBlue hover:text-medBlue
}

.tab-button.active {
    @apply text-medBlue font-bold
}